import { NavLink } from '@remix-run/react'
import { motion } from 'framer-motion'
import { paths } from './paths.ts'

export default function NavigationBar() {
	return (
		<div className="z-40 flex w-full flex-col sm:flex-row justify-around text-foreground p-3 tracking-tight">
			<NavLink className="flex items-center justify-center mb-2 sm:mb-0" to="/">
				Nicolás F. Mainella
			</NavLink>
			<nav className="w-full sm:w-auto">
				<ul className="flex flex-row justify-center sm:justify-evenly p-1 sm:pl-10 sm:pr-10">
					{paths.map(path => (
						<motion.li
							key={path.name}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.9 }}
							transition={{ duration: 0.2 }}
						>
							<NavLink
								className={({ isActive }) => {
									return `activeLink rounded pb-2 pl-2 pr-2 sm:pl-4 sm:pr-4 pt-2 ${
										isActive ? 'border-primary font-bold underline' : ''
									}`
								}}
								to={path.to}
							>
								{path.name}
							</NavLink>
						</motion.li>
					))}
				</ul>
			</nav>
		</div>
	)
}
